import { AlertDefault, AlertType } from "@/types/helpers/alert-type";
import { VuexModule, Module, Mutation } from "vuex-module-decorators";
const name = "Helpers";

@Module({ name: name })
export default class Helpers extends VuexModule {
  private alert: AlertType = AlertDefault;
  private show = false;

  @Mutation
  public showAlert(payload: AlertType): void {
    Object.assign(this.alert, payload);
    this.show = true;
  }

  @Mutation
  public disableAlert(): void {
    Object.assign(this.alert, AlertDefault);
    this.show = false;
  }

  get alerted(): boolean {
    return this.show;
  }

  get getAlert(): AlertType {
    return this.alert;
  }
}
