import { LevelEnum } from "../enum/level-enum";

// Used in Vuex
export interface AccountType {
  _id: string;
  access_token: string;
  username: string;
  email: string;
  image?: string;
  level?: LevelEnum;
}

export const AccountTypeDefault: AccountType = {
  _id: "",
  username: "",
  access_token: "",
  email: "",
  image: "https://avatars.githubusercontent.com/u/21698755?s=88&v=4",
  level: undefined,
};
//

export interface LoginType {
  email: string;
  password: string;
  remember_me: boolean;
}

// Login default type
export const LoginTypeDefault: LoginType = {
  email: "",
  password: "",
  remember_me: false,
};
