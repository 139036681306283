import Account from "./modules/auth.module";
import Helpers from "./modules/helpers.module";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import Vuex from "vuex";

const ls = new SecureLS({ encodingType: "aes", isCompression: false, encryptionSecret: process.env.VUE_APP_STR_PWD });

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ["Account"],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {},
  mutations: {},
  actions: {},
  modules: { Account, Helpers },
  strict: process.env.DEV,
});

export default store;
