
import { Options, Vue } from "vue-class-component";
import NavBarComponent from "./components/NavBarComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";
import Alert from "./components/Alert.vue";

@Options({
  components: {
    NavBarComponent,
    FooterComponent,
    Alert,
  },
})
export default class App extends Vue {}
