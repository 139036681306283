import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed mx-auto mt-2 left-0 right-0 w-2/3"
}
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "py-1 text-gray-900" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { class: "text-sm" }
const _hoisted_7 = {
  key: 0,
  class: "mt-5 text-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckCircleIcon = _resolveComponent("CheckCircleIcon")!
  const _component_ExclamationIcon = _resolveComponent("ExclamationIcon")!
  const _component_InformationCircleIcon = _resolveComponent("InformationCircleIcon")!
  const _component_XCircleIcon = _resolveComponent("XCircleIcon")!
  const _component_XIcon = _resolveComponent("XIcon")!

  return (_ctx.handleAlert())
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(['border-t-2 rounded-b px-4 py-3 shadow-md ' + this.alert.colour]),
          role: "alert"
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                (this.alert.icon === 'succ')
                  ? (_openBlock(), _createBlock(_component_CheckCircleIcon, {
                      key: 0,
                      class: "h-10 w-10 mr-4",
                      "aria-hidden": "true"
                    }))
                  : (this.alert.icon === 'warn')
                    ? (_openBlock(), _createBlock(_component_ExclamationIcon, {
                        key: 1,
                        class: "h-10 w-10 mr-4",
                        "aria-hidden": "true"
                      }))
                    : (this.alert.icon === 'info')
                      ? (_openBlock(), _createBlock(_component_InformationCircleIcon, {
                          key: 2,
                          class: "h-10 w-10 mr-4",
                          "aria-hidden": "true"
                        }))
                      : (_openBlock(), _createBlock(_component_XCircleIcon, {
                          key: 3,
                          class: "h-10 w-10 mr-4",
                          "aria-hidden": "true"
                        }))
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(this.alert.headline), 1),
                _createElementVNode("p", _hoisted_6, _toDisplayString(this.alert.description), 1),
                (this.alert.data !== null && typeof this.alert.data === 'string')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(this.alert.data), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hide()))
            }, [
              _createVNode(_component_XIcon, { class: "h-10 w-10" })
            ])
          ])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}