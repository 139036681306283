import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/Home.vue"),
  },
  {
    path: "/scoreboard",
    name: "Scoreboard",
    component: () => import("../views/Scoreboard.vue"),
  },
  {
    path: "/courses",
    name: "Courses",
    component: () => import("../views/Courses.vue"),
  },
  {
    path: "/courses/:course/:year/:course_id",
    name: "QuestionList",
    component: () => import("../views/Questions.vue"),
    props: true,
  },
  {
    path: "/courses/:course/:year/:course_id/:question_id",
    name: "QuestionDetail",
    component: () => import("../views/Question.vue"),
    props: true,
  },
  {
    path: "/courses/:course/:year/:course_id/new",
    name: "QuestionNew",
    component: () => import("../views/QuestionNew.vue"),
    props: true,
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/teacher",
    name: "Teacher",
    component: () => import("../views/Teacher.vue"),
  },
  {
    path: "/teacher/:course_id",
    name: "CourseAttendee",
    component: () => import("../views/CourseAttendee.vue"),
  },
  {
    path: "/iforgor",
    name: "ForgotPassword",
    component: () => import("../views/ForgotPassword.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/Settings.vue"),
  },
  {
    path: "/administration",
    name: "Administration",
    component: () => import("../views/Administration.vue"),
  },
  {
    path: "/usermanagement",
    name: "UserManagement",
    component: () => import("../views/UserManagement.vue"),
  },
  {
    path: "/course/new",
    name: "CourseNew",
    component: () => import("../views/CourseNew.vue"),
  },
  {
    path: "/search/:expression",
    name: "Search",
    component: () => import("../views/Search.vue"),
  },
  {
    path: "/question/:id/close",
    name: "FinalAnswer",
    component: () => import("../views/FinalAnswer.vue"),
  },
  {
    path: "/question/:question_id",
    name: "QuestionSearched",
    component: () => import("../views/Question.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "pressed_link",
});

export default router;
