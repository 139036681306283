import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col min-h-screen" }
const _hoisted_2 = { class: "container mx-auto px-4 flex-grow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nav_bar_component = _resolveComponent("nav-bar-component")!
  const _component_alert = _resolveComponent("alert")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_component = _resolveComponent("footer-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_nav_bar_component),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_alert),
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_footer_component)
  ]))
}