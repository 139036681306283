
import { Options, Vue } from "vue-class-component";
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuItem, MenuItems, MenuButton } from "@headlessui/vue";
import { MenuIcon, XIcon, SearchIcon } from "@heroicons/vue/outline";
import store from "../store";
import { NavigationType } from "../types/ui/navigation-types";

@Options({
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuItem,
    MenuItems,
    MenuButton,
    MenuIcon,
    XIcon,
    SearchIcon,
  },
})
export default class NavBar extends Vue {
  private search_expression = "";

  handleLogout(): void {
    store.commit("accountLogout");
    this.$router.push({ name: "Login" });
  }

  redirect(): void {
    this.$router.push({ name: "Search", params: { expression: this.search_expression } });
  }

  isLoggedIn = (): boolean => store.getters.isLoggedIn;

  private getLevel(): boolean {
    return store.getters.getLevel;
  }

  private imageGetter(): string {
    return store.getters.getImage;
  }

  private navItems(): Array<NavigationType> {
    let nav = [
      { name: "Home", link: "Home" },
      { name: "Žebříček", link: "Scoreboard" },
      { name: "Předměty", link: "Courses" },
    ];

    if (!this.isLoggedIn()) {
      nav.push({ name: "Registrovat se", link: "Register" }, { name: "Přihlásit se", link: "Login" });
    }
    return nav;
  }
}
