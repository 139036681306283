export interface AlertType {
  headline: string;
  description: string;
  icon: string;
  data?: unknown;
  colour: string;
  time: number;
}

export const AlertDefault: AlertType = {
  headline: "",
  description: "",
  icon: "",
  data: null,
  colour: "",
  time: 0,
};
