import { LevelEnum } from "@/types/enum/level-enum";
import { AccountType, AccountTypeDefault } from "@/types/user/login-type";
import { VuexModule, Module, Mutation } from "vuex-module-decorators";

const name = "Account";

@Module({ name: name })
export default class User extends VuexModule {
  private account: AccountType = AccountTypeDefault;

  @Mutation
  public accountLogin(payload: AccountType): void {
    // Image not implemented yet
    if (payload.image == null) {
      payload.image = this.account.image;
    }
    Object.assign(this.account, payload);
  }

  @Mutation
  public accountLogout(): void {
    Object.assign(this.account, AccountTypeDefault);
  }

  @Mutation
  public changeImage(payload: string): void {
    this.account.image = payload;
  }

  @Mutation
  public changeUsername(payload: string): void {
    this.account.username = payload;
  }

  @Mutation
  public changeEmail(payload: string): void {
    this.account.email = payload;
  }

  get getAccessToken(): string {
    return this.account.access_token;
  }

  get getAccount(): AccountType {
    return this.account;
  }

  get getLevel(): LevelEnum | undefined {
    return this.account.level;
  }

  get getImage(): string | undefined {
    return this.account.image;
  }

  get getEmail(): string {
    return this.account.email;
  }

  get getUsername(): string {
    return this.account.username;
  }

  get isLoggedIn(): boolean {
    return this.account.access_token.length > 0;
  }
}
