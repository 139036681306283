
import { Options, Vue } from "vue-class-component";
import store from "../store";
import { AlertType } from "../types/helpers/alert-type";
import { hideAlert } from "../helpers/alerts";
import { CheckCircleIcon, ExclamationIcon, InformationCircleIcon, XCircleIcon, XIcon } from "@heroicons/vue/solid";

@Options({
  components: { CheckCircleIcon, ExclamationIcon, InformationCircleIcon, XCircleIcon, XIcon },
})
export default class Alert extends Vue {
  private alert: AlertType = store.getters.getAlert;

  private handleAlert(): boolean {
    if (store.getters.alerted) {
      setTimeout(function (): void {
        hideAlert();
      }, this.alert.time);
      return true;
    }
    return false;
  }

  private hide(): void {
    hideAlert();
  }
}
