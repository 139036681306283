import store from "@/store";

interface SeverityTypeDef {
  colour: string;
  icon: string;
}

export const AlertSeverities = {
  warn: {
    colour: "bg-yellow-300",
    icon: "warn",
  },
  info: {
    colour: "bg-blue-300",
    icon: "info",
  },
  fatal: {
    colour: "bg-red-300",
    icon: "fatal",
  },
  success: {
    colour: "bg-green-300",
    icon: "succ",
  },
};

export function showAlert(headline: string, description: string, severity: SeverityTypeDef, data: unknown = null, time = 5000): void {
  store.commit("showAlert", {
    headline: headline,
    description: description,
    data: data,
    icon: severity.icon,
    colour: severity.colour,
    time: time,
  });
}

export function hideAlert(): void {
  store.commit("disableAlert");
}
